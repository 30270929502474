import { createStore } from 'vuex'
import api from "@/store/api";
import axios from "axios";
import router from "@/router";

export default createStore({
  state: {
    isModal: false,
    forms: [],
    form: {
      sections: {},
      respondents: [],
      successMsg: ''
    },
    answers: {},
    reqStatus: 'idle'
  },
  getters: {
    results: state => {
      const questions = Object
        .keys(state.form.sections)
        .filter(key => key !== 'overview')
        .reduce((acc, key) => {
          // acc.push({question: state.form.sections[key].options[0].text, q_key: key}) - if we don't have q title
          acc.push({question: state.form.sections[key].title, q_key: key})
          return acc
        }, [{q_key: 'email'}])

      const results = state.form.respondents.reduce((acc, curr) => {
        const answers = Object.keys(curr.answers).reduce((acc, key) => {
          const text = curr.answers[key][key + '_text'] || ''
          const value = typeof curr.answers[key][key] === 'object'
            ? Object.keys(curr.answers[key][key]).join(', ')
            : curr.answers[key][key]
          acc[key] = `${value} ${text}`
          return acc
        }, {})
        acc.push({
          email: curr.createdBy, ...answers
        })
        return acc
      }, [])

      const response = []

      questions.forEach((val) => {
        const answers = results.map(o => o[val.q_key])
        response.push([val.question || val.q_key, ...answers])
      })

      return response
    }
  },
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value
    },
    ADD_FORM (state, form) {
      state.forms.push(form)
    },
    SET_REQ_STATUS (state, status) {
      switch (status) {
        case 'idle':
          setTimeout(() => {
            state.reqStatus = status
          }, 3000)
          break;
        case 'success':
          state.reqStatus = status
          setTimeout(() => {
            state.reqStatus = 'idle'
          }, 5000)
          break;
        default:
          state.reqStatus = status
      }
    },
    LOAD_FORM_TEMPLATE (state, formId) {
      state.form = state.forms.find(o => o.id === formId)
    },
    SET_FORM (state, data) {
      state.form = data
    },
    SET_SECTION_OPTIONS_VALUE (state, data) {
      const index = state.form.sections[data.key].options.findIndex(o => o.name === data.name)
      if (data.type === 'checkboxGroup') {
        const idx = state.form.sections[data.key].options[index].list.findIndex(o => o.label === data.label)
        state.form.sections[data.key].options[index].list[idx].checked = data.checked
        return
      }
      state.form.sections[data.key].options[index].value = data.value
    },
    SAVE_ANSWERS (state, {section, name, value, type, checked}) {
      if (!state.answers[section]) {
        state.answers[section] = {}
      }
      state.answers[section][name] = type === 'checkboxGroup' ? {...state.answers[section][name], [value]: checked} : value
    }
  },
  actions: {
    async get_forms ({commit}) {
      try {
        const { data } = await axios.get(api.forms)
        commit('SET_STATE', {key: 'forms', value: data})
      } catch (err) {
        console.log(err)
      }
    },
    async get_form ({state, commit}, params) {
      try {
        const url = `${api.forms}/${params.formId}`
        const { data } = await axios.get(url)
        commit('SET_STATE', {key: 'form', value: data})
      } catch (err) {
        console.log(err)
      }
    },
    async save_form ({state, commit}, params) {
      try {
        commit('SET_REQ_STATUS', 'sending')
        const url = `${api.forms}/${params.formId}/${params.email}`
        await axios.post(url, {email: params.email, answers: state.answers})
        commit('SET_REQ_STATUS', 'success')
      } catch (err) {
        console.log(err)
      }
    },
    async update_form ({state, commit}, params) {
      try {
        commit('SET_REQ_STATUS', 'sending')
        const url = `${api.forms}/${params.formId}/${params.email}`
        const { answers } = state.form.respondents.find(o => o.email === params.email)
        await axios.put(url, {email: params.email, answers: {...answers, ...state.answers}})
        commit('SET_REQ_STATUS', 'success')
      } catch (err) {
        console.log(err)
      }
    },
    async save_new_form ({state, commit}, form) {
      try {
        commit('SET_REQ_STATUS', 'sending')
        const { data } = await axios.post(`${api.forms}/new`, form)
        commit('ADD_FORM', {...form, _id: data.id })
        commit('SET_REQ_STATUS', 'success')
        await router.push('/dashboard')
      } catch (err) {
        console.log(err)
      }
    },
  },
  modules: {
  }
})
