<template>
  <main class="home-view">
    <div class="main-content">
      <h1 class="text-center">{{ form.title }}</h1>

      <div v-if="!isSent">
        <section v-for="({ title, options }, key, idx) in sections"
                 :key="key">
          <h2 v-html="title" />
          <ul v-if="options">
            <li v-for="(item, index) in options"
                :key="index">
              <p v-if="item.type === 'paragraph'"
                 v-html="item.text"/>
              <div v-if="item.type === 'radioGroup'">
                <RadioGroup :name="item.name"
                            :sectionName="key"
                            type="radio"
                            :options="item.options"
                            :answer="answers[key] ? answers[key][item.name] : ''"
                            @select="handleRadioGroup"/>
              </div>
              <div v-if="item.type === 'checkboxGroup'">
                <CheckboxGroup :name="item.name"
                               :sectionName="key"
                               :options="item.list"
                               :answers="answers[key] ? Object.keys(answers[key][item.name]) : []"
                               @select="handleCheckboxGroup"/>
              </div>
              <div v-if="item.type === 'steps'">
                <TextSteps :data="item"/>
              </div>
              <textarea v-if="item.type === 'textarea'"
                        :placeholder="item.placeholder"
                        @input="handleInput($event, key, item.name)">{{ answers[key] ? answers[key][item.name] : '' }}</textarea>
            </li>
          </ul>
        </section>

        <div v-if="params.email"
             class="actions-box">
          <p v-if="isSending"
             class="text-center m-b-24">
            Va rugam asteptati, datele sunt in curs de salvare.
          </p>
          <p v-if="isSuccess"
             class="text-center m-b-24">
            Datele au fost salvate cu succes.
          </p>
          <button v-if="params.email"
                  class="no-border block mauto"
                  @click="handleSave">Save</button>
        </div>
      </div>

      <div v-if="isSent">
        <p class="text-center" v-html="form.successMsg" />
      </div>
    </div>
  </main>
</template>

<script setup>
  import {computed, ref, watch} from "vue";
  import {useStore} from "vuex";
  import {useRoute} from 'vue-router';
  import RadioGroup from "@/components/RadioGroup.vue";
  import CheckboxGroup from "@/components/CheckboxGroup.vue";
  import TextSteps from "@/components/TextSteps.vue";

  const {dispatch, commit, state} = useStore()
  const {params} = useRoute()
  dispatch('get_form', params)
  const form = computed(() => state.form)
  const isSending = computed(() => state.reqStatus === 'sending')
  const isSuccess = computed(() => state.reqStatus === 'success')
  const sections = computed(() => {
    return state.form.sections
  })
  const answers = computed(() => {
    if (params.email && state.form.respondents?.length) {
      const index = state.form.respondents.findIndex(o => o.email === params.email)
      if (index > -1) {
        return state.form.respondents[index].answers
      }
    }
    return {}
  })
  const isSent = ref(false)

  watch(isSuccess, function () {
    isSent.value = true
  })

  function handleRadioGroup(data) {
    commit('SAVE_ANSWERS', data)
  }

  function handleCheckboxGroup(data) {
    commit('SAVE_ANSWERS', data)
  }

  function handleInput(ev, section, name) {
    commit('SAVE_ANSWERS', {section, name, value: ev.target.value})
  }

  function handleSave() {
    const isUser = form.value.respondents.some(respondent => respondent.email === params.email)
    dispatch(isUser ? 'update_form' : 'save_form', params)
  }
</script>

<style lang="scss">
  .home-view {
    @media only screen and (min-width: 0) {
      .main-content {
        max-width: 800px;
        margin: 0 auto;
        padding: 48px 24px 400px;

        h1 {
          margin-bottom: 48px;
        }

        section {
          margin-bottom: 24px;
        }

        .actions-box {
          button {
            width: 120px;
            padding: 4px 24px;
            border-radius: 4px;
            color: white;
            background-color: #1eb386;
          }
        }
      }
    }
  }
</style>
