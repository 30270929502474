<template>
  <div class="checkbox-group flex">
    <label :for="item.label"
           class="pointer"
           v-for="(item, index) in options"
           :key="index">
      <input class="pointer"
             :name="name"
             :id="item.label"
             :type="'checkbox' || type"
             @change="handleSelect($event, item)"
             :checked="answers.includes(item.label)" />
      <span :class="{bold: item.text}">{{ item.label }}</span><br>
      <span v-if="item.text"
            class="block"
            v-html="item.text" />
    </label>
  </div>
</template>

<script setup>
  const props = defineProps({
    sectionName: String,
    name: String,
    type: String,
    options: Array,
    answers: Array,
  })

  const emits = defineEmits(['select'])

  function handleSelect (ev, value) {
    emits('select', {name: props.name, type: 'checkboxGroup', section: props.sectionName, value: value.label, checked: ev.target.checked})
  }
</script>

<style lang="scss">
  .checkbox-group {
    @media only screen and (min-width: 0) {
      flex-direction: column;
      label {
        margin-right: 16px;
        margin-bottom: 8px;
        span {
          margin-left: 8px;
        }
        span:last-child {
          margin-left: 22px;
          margin-top: 4px;
          line-height: 1.4;
          font-size: 1.8rem;
        }
      }
    }
  }
</style>