<template>
  <div class="radio-group flex">
    <label :for="`${name}-${item.label}`"
           class="pointer"
           v-for="(item, index) in options"
           :key="index">
      <input class="pointer"
             :name="name"
             :id="`${name}-${item.label}`"
             type="radio"
             @change="handleSelect(item)"
             :checked="answer === item.label" />
      <span>{{ item.label }}</span><br>
    </label>
  </div>
</template>

<script setup>
  const props = defineProps({
    sectionName: String,
    name: String,
    options: Array,
    answer: String,
  })

  const emits = defineEmits(['select'])

  function handleSelect (value) {
    emits('select', {name: props.name, section: props.sectionName, value: value.label})
  }
</script>

<style lang="scss">
  .radio-group {
    @media only screen and (min-width: 0) {
      flex-direction: column;
      label {
        margin-right: 16px;
        margin-bottom: 8px;
        span {
          margin-left: 8px;
        }
      }
    }
  }
</style>