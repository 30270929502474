import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import RadioGroup from '@/components/RadioGroup.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import TextSteps from '@/components/TextSteps.vue';
import BaseModal from "@/components/BaseModal.vue";
import './styles/helper-classes.scss';
import './styles/typography.scss';
import './styles/grid.scss';
import './styles/base.scss';

createApp(App)
  .use(store)
  .use(router)
  .component('BaseModal', BaseModal)
  .component('TextSteps', TextSteps)
  .component('RadioGroup', RadioGroup)
  .component('CheckboxGroup', CheckboxGroup)
  .mount('#app')
