<template>
  <div
    v-if="isModal"
    cy="base-modal"
    class="base-modal z99 flex-center wh100 fixed left0 top0"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';
  import { computed, onUpdated } from 'vue';

  const { state } = useStore();
  const isModal = computed(() => state.isModal);

  onUpdated(function () {
    document.body.style.position = isModal.value ? 'fixed' : 'relative';
    document.body.style.overflow = isModal.value ? 'hidden' : 'scroll';
  });
</script>

<style lang="scss">
  .base-modal {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(15px);
    @media only screen and (min-width: 0) {
      > div {
        width: 100%;
        max-width: 500px;
        margin: 24px;
        padding: 24px;
        background-color: white;
        border-radius: 4px;
      }
    }

    @media only screen and (max-width: 600px) {
      > div {
        height: 100%;
        overflow: scroll;
      }
    }
  }
</style>
